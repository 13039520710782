import "./NavBar.css";
import {NavLink} from "react-router-dom";
import {useUserContext} from "../../../context/userContext/context";
import {useEffect} from "react";
import Loader from "../loader/Loader";

const NavBar = () => {

    const {user, userLoading, getUserData, logoutUser} = useUserContext()
    useEffect(() => {
        getUserData()
    }, [])
    console.log(user)
    if (userLoading) return <nav><Loader/></nav>
    if (!user || user.needAuth) return <nav>
        <NavLink to={'/login'}
                 className={({isActive, isPending}) =>
                     isPending ? "navLink" : isActive ? "navLink active" : "navLink"
                 }>
            Login
        </NavLink>
    </nav>

    return <nav>





        <NavLink
            to={'/'}
            className={({isActive, isPending}) =>
                isPending ? "navLink" : isActive ? "navLink active" : "navLink"
            }>
          Title & Tags
        </NavLink>


        <NavLink
            to={'/tagsGenerator'}
            className={({isActive, isPending}) =>
                isPending ? "navLink" : isActive ? "navLink active" : "navLink"
            }>
            Tags Only
        </NavLink>

    </nav>;
};
export default NavBar;
