import {BrowserRouter, Route, Routes} from "react-router-dom";
import IndexPage from "./components/pages/index/IndexPage";
import NavBar from "./components/partials/navbar/NavBar";
import LoginPage from "./components/pages/login/LoginPage";
import TagsOnlyPage from "./components/pages/tagsOnlyPage/TagsOnlyPage";

function App() {
    return (
        <BrowserRouter>
            <div className="app">
<NavBar/>
                {/*App body  todo add route to backend const routeList!!!*/}
                <div className="app__body">
                    <Routes>
                        <Route
                            exact
                            path="/"
                            element={
                                <IndexPage/>
                            }
                        />
                        <Route
                            exact
                            path="/login"
                            element={
                                <LoginPage/>
                            }
                        />
                        <Route
                            exact
                            path="/tagsGenerator"
                            element={
                                <TagsOnlyPage/>
                            }
                        />


                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
