import "./IndexPage.css";
import {useUserContext} from "../../../context/userContext/context";
import {
    capitalizeFirstLetter,
    copyStringToClipboard,
    findUniqueDuplicates,
    shuffle,
    timeSince
} from '../../../utils/utils'
import {Link, NavLink} from "react-router-dom";
import {useEffect, useState} from "react";

const IndexPage = () => {
    const {user, words, combos, getPanelData, storeCombo} = useUserContext()
    const [first, setFirst] = useState(null);
    const [second, setSecond] = useState(null);
    const [third, setThird] = useState(null);
    const [fourth, setFourth] = useState(null);
    const [tempTags, setTempTags] = useState({})
    const [tempComments, setTempComments] = useState({})

    useEffect(() => {
        getPanelData()
    }, []);
    const generateTitle = () => {
        let firstPart = [], secondPart = [], thirdPart = [], fourthPart = [];
        let textSoFar = '';
        let comboWords = [];
        const generateFirstPart = () => {
            let types = shuffle(words.filter(w => w.category === "firstStart")).sort((a, b) => a.useCases.length - b.useCases.length)
            let pets = shuffle(words.filter(w => w.category === 'pet')).sort((a, b) => a.useCases.length - b.useCases.length)
            let objects = shuffle(words.filter(w => w.category === 'object')).sort((a, b) => a.useCases.length - b.useCases.length)
            let sources = shuffle(words.filter(w => w.category === 'source')).sort((a, b) => a.useCases.length - b.useCases.length)

            let selType = types[0]
            let selSource = sources[0]
            let selObject = objects[0]

            firstPart.push(selType)
            if (!selSource.text.split(' ').find(p => pets.map(a => a.text).includes(p))) {
                let selPet = pets[0]
                firstPart.push(selPet)
            } else {
                firstPart.push({text: "", uniqueString: ""})
            }
            firstPart.push(selObject)
            firstPart.push(selSource)
            textSoFar += firstPart.filter(a => a.text).map(a => a.text).join(' ') + ', '

        }
        generateFirstPart()

        const generateSecondPart = () => {
            let bannedWords = textSoFar.replaceAll(',', '').trim().split(' ');
            let starts = shuffle(words.filter(w => w.category === "secondStart" && !w.text.split(' ').find(wp => bannedWords.includes(wp)))).sort((a, b) => a.useCases.length - b.useCases.length)
            let styles = shuffle(words.filter(w => w.category === "style" && !w.text.split(' ').find(wp => bannedWords.includes(wp)))).sort((a, b) => a.useCases.length - b.useCases.length)
            let pets = shuffle(words.filter(w => w.category === "pet" && !w.text.split(' ').find(wp => bannedWords.includes(wp)))).sort((a, b) => a.useCases.length - b.useCases.length)
            let objects = shuffle(words.filter(w => w.category === "object" && !w.text.split(' ').find(wp => bannedWords.includes(wp)))).sort((a, b) => a.useCases.length - b.useCases.length)
            let end = shuffle(words.filter(w => w.category === "secondEnd" && !w.text.split(' ').find(wp => bannedWords.includes(wp)))).sort((a, b) => a.useCases.length - b.useCases.length)

            let selStart = starts[0]
            let selStyle = styles[0]
            let selPet = pets[0]
            let selObject = objects[0]
            let selEnd = end[0]

            secondPart.push(selStart)
            secondPart.push(selStyle)
            secondPart.push(selPet)
            secondPart.push(selObject)
            secondPart.push(selEnd)

            textSoFar += secondPart.map(a => a.text).join(' ') + ', '

        }
        generateSecondPart()


        const generateThirdPart = () => {
            let bannedWords = textSoFar.replaceAll(',', '').trim().split(' ');
            let occasions = shuffle(words.filter(w => w.category === "titleOccasion" && !w.text.split(' ').find(wp => bannedWords.includes(wp)))).sort((a, b) => a.useCases.length - b.useCases.length)
            let pets = shuffle(words.filter(w => w.category === "pet" && !w.text.split(' ').find(wp => bannedWords.includes(wp)))).sort((a, b) => a.useCases.length - b.useCases.length)
            let owners = shuffle(words.filter(w => w.category === "owner" && !w.text.split(' ').find(wp => bannedWords.includes(wp)))).sort((a, b) => a.useCases.length - b.useCases.length)
            let whats = shuffle(words.filter(w => w.category === "what" && !w.text.split(' ').find(wp => bannedWords.includes(wp)))).sort((a, b) => a.useCases.length - b.useCases.length)

            let selOccasion = occasions[0]
            let selPet = pets[0]
            let selOwner = owners[0]
            let selWhat = whats[0]

            thirdPart.push(selOccasion)
            thirdPart.push(selPet)
            thirdPart.push(selOwner)
            thirdPart.push(selWhat)
            textSoFar += thirdPart.map(a => a.text).join(' ') + ', '
        }
        generateThirdPart()

        const generateFourthPart = () => {
            let bannedWords = textSoFar.replaceAll(',', '').trim().split(' ');
            let remainingChars = 140 - textSoFar.length
            let breeds = shuffle(words.filter(w => w.text.length <= remainingChars && w.category === 'titleBreed' && !w.text.split(' ').find(wp => bannedWords.includes(wp)))).sort((a, b) => a.useCases.length - b.useCases.length)
            let maxCharBreeds = breeds.filter(w => w.text.length === remainingChars)
            let selBreed = breeds[0]
            if (maxCharBreeds.length) selBreed = maxCharBreeds[0]
            if (selBreed) fourthPart.push(selBreed)
            textSoFar += fourthPart.map(a => a.text).join(' ')

        }
        generateFourthPart()
        console.log(textSoFar, textSoFar.length)

        setFirst(firstPart)
        setSecond(secondPart)
        setThird(thirdPart)
        setFourth(fourthPart)
    }

    const editWord = (e, part, index) => {
        let parts = {first, second, third, fourth}
        switch (part) {
            case "first":
                let newFirst = [...first]
                let newWord = words.find(a => a.uniqueString === e.target.value) || {uniqueString: "", text: ""}
                newFirst[index] = newWord
                setFirst(newFirst)
                break
            case "second":
                let newSecond = [...second]
                newSecond[index] = words.find(a => a.uniqueString === e.target.value)
                setSecond(newSecond)
                break
            case "third":
                let newThird = [...third]
                newThird[index] = words.find(a => a.uniqueString === e.target.value)
                setThird(newThird)

                break
            case "fourth":
                let newFourth = [...fourth]
                newFourth[index] = words.find(a => a.uniqueString === e.target.value)
                setFourth(newFourth)

                break
            default:

                break
        }

    }

    let text = first?.filter(a => a.text).map(a => a.text).join(' ') + ', ' + second?.map(a => a.text).join(' ') + ', ' + third?.map(a => a.text).join(' ') + ', ' + fourth?.map(a => a.text).join('')

    const storeTitleFunc = () => {
        let combo = {text, usedWords: text.replaceAll(',', '').split(' '), title: [first, second, third, fourth]}
        if (text.length <= 140) {
            storeCombo(combo)
            setFirst(null)
            setSecond(null)
            setThird(null)
            setFourth(null)
        }
    }

    const generateTags = (c, tagsData = {texts: [], data: []}) => {
        let tempArr = [...c.usedWords]
        let tagCats = ['tagCombos', 'tagBreeds', 'tagOccasions', 'tagFamily', 'tagShortName', 'tagPetDesc',
            'tagObjectDesc', 'tagObject', 'tagVerb', 'tagNumeric', 'tagSize', 'tagAnimals', 'tagMaterials', 'tagOthers']
        let {texts, data} = tagsData
        while (texts.length < 13) {
            let newTag = '', building = true
            while (building) {
                let maxTagLen = (20 - newTag.length), minTagLen = Math.max((20 - newTag.length) - 13, 0);
                let tagWords = shuffle(words.filter(w => w.text.length <= maxTagLen && w.text.length > minTagLen && tagCats.includes(w.category) && !w.text.split(' ').find(p => tempArr.includes(p)))).sort((a, b) => a.useCases.length - b.useCases.length)
                let randomTag = tagWords[0]
                if (randomTag) {
                    newTag += randomTag.text
                    data.push(randomTag)
                    tempArr.push(...(randomTag.text.split(' ')))
                    if (newTag.length < 20) newTag += ' '
                    else {

                        texts.push(newTag.trim())
                    }
                } else {
                    building = false
                    if (!texts.includes(newTag.trim())) {
                        texts.push(newTag.trim())
                    }
                }
            }
        }
        let newTemp = {...tempTags}
        newTemp['tt' + c._id] = {texts, data}
        setTempTags(newTemp)
    }

    const saveTagsFunc = (e) => {
        let _id = e.target.getAttribute('data-id')
        storeCombo({
            text: combos.find(c => c._id === _id).text,
            tags: tempTags["tt" + _id].texts,
            tagData: tempTags["tt" + _id].data
        })
        let newTemp = {...tempTags}
        delete newTemp["tt" + _id]
        setTempTags(newTemp)
    }

    const removeTagFunc = (e) => {
        let cid = e.target.getAttribute('data-cid')
        let tindex = e.target.getAttribute('data-tindex')
        let combo = combos.find(c => c._id === cid)
        let tagsData = tempTags[`tt${cid}`]
        tagsData.texts.splice(tindex, 1)
        tagsData.data.splice(tindex, 1)

        generateTags(combo, tagsData)
    }

    const setUsedFunc = (e) => {
        storeCombo({text: e.target.getAttribute('data-text'), used: e.target.checked})
    }
    const storeTempComment = (e) => {
        let newTemp = {...tempComments}
        let id = e.target.getAttribute('data-id')
        newTemp['c' + id] = e.target.value
        setTempComments(newTemp)
    }
    const updateCommentFunc = (e) => {
        storeCombo({text: e.target.getAttribute('data-text'), comment: e.target.value})
    }
    let duplicates = findUniqueDuplicates(text.replaceAll(',', '').split(' '))
    return <div>
        <button onClick={generateTitle}>Generate</button>
        {first && <h3>{text} <strong style={{color: "orange"}}>({text.length})</strong>
            <button onClick={storeTitleFunc} style={{background: text.length <= 140 ? 'green' : 'red'}}>Store</button>
        </h3>}
        {duplicates.length > 0 && <strong> Duplicates: {duplicates.join("; ")}<br/></strong>}

        {first && <div id={'firstPart'}>
            <select onChange={(e) => editWord(e, 'first', 0)} value={first[0].uniqueString}>
                {words.filter(w => w.category === 'firstStart').sort((a, b) => a.text.localeCompare(b.text)).map(w =>
                    <option key={w._id}
                            value={w.uniqueString}>{w.text} ({w.text.length - first[0].text.length})</option>)}
            </select>


            <select onChange={(e) => editWord(e, 'first', 1)} value={first[1].uniqueString}>
                <option value={""}></option>
                {words.filter(w => w.category === 'pet').sort((a, b) => a.text.localeCompare(b.text)).map(w => <option
                    key={w._id}
                    value={w.uniqueString}>{w.text} ({w.text.length - first[1].text.length})</option>)}
            </select>


            <select onChange={(e) => editWord(e, 'first', 2)} value={first[2].uniqueString}>
                {words.filter(w => w.category === 'object').sort((a, b) => a.text.localeCompare(b.text)).map(w =>
                    <option key={w._id}
                            value={w.uniqueString}>{w.text} ({w.text.length - first[2].text.length})</option>)}
            </select>
            <select onChange={(e) => editWord(e, 'first', 3)} value={first[3].uniqueString}>
                {words.filter(w => w.category === 'source').sort((a, b) => a.text.localeCompare(b.text)).map(w =>
                    <option key={w._id}
                            value={w.uniqueString}>{w.text} ({w.text.length - first[3].text.length})</option>)}
            </select>
        </div>}
        {second && <div id={'secondPart'}>
            <select onChange={(e) => editWord(e, 'second', 0)} value={second[0].uniqueString}>
                {words.filter(w => w.category === 'secondStart').sort((a, b) => a.text.localeCompare(b.text)).map(w =>
                    <option key={w._id}
                            value={w.uniqueString}>{w.text} ({w.text.length - second[0].text.length})</option>)}
            </select>


            <select onChange={(e) => editWord(e, 'second', 1)} value={second[1].uniqueString}>
                <option value={""}></option>
                {words.filter(w => w.category === 'style').sort((a, b) => a.text.localeCompare(b.text)).map(w => <option
                    key={w._id}
                    value={w.uniqueString}>{w.text} ({w.text.length - second[1].text.length})</option>)}
            </select>


            <select onChange={(e) => editWord(e, 'second', 2)} value={second[2].uniqueString}>
                {words.filter(w => w.category === 'pet').sort((a, b) => a.text.localeCompare(b.text)).map(w => <option
                    key={w._id}
                    value={w.uniqueString}>{w.text} ({w.text.length - second[2].text.length})</option>)}
            </select>
            <select onChange={(e) => editWord(e, 'second', 3)} value={second[3].uniqueString}>
                {words.filter(w => w.category === 'object').sort((a, b) => a.text.localeCompare(b.text)).map(w =>
                    <option key={w._id}
                            value={w.uniqueString}>{w.text} ({w.text.length - second[3].text.length})</option>)}
            </select>
            <select onChange={(e) => editWord(e, 'second', 4)} value={second[4].uniqueString}>
                {words.filter(w => w.category === 'secondEnd').sort((a, b) => a.text.localeCompare(b.text)).map(w =>
                    <option key={w._id}
                            value={w.uniqueString}>{w.text} ({w.text.length - second[4].text.length})</option>)}
            </select>
        </div>}
        {third && <div id={'thirdPart'}>
            <select onChange={(e) => editWord(e, 'third', 0)} value={third[0].uniqueString}>
                {words.filter(w => w.category === 'titleOccasion').sort((a, b) => a.text.localeCompare(b.text)).map(w =>
                    <option key={w._id}
                            value={w.uniqueString}>{w.text} ({w.text.length - third[0].text.length})</option>)}
            </select>


            <select onChange={(e) => editWord(e, 'third', 1)} value={third[1].uniqueString}>
                <option value={""}></option>
                {words.filter(w => w.category === 'pet').sort((a, b) => a.text.localeCompare(b.text)).map(w => <option
                    key={w._id}
                    value={w.uniqueString}>{w.text} ({w.text.length - third[1].text.length})</option>)}
            </select>


            <select onChange={(e) => editWord(e, 'third', 2)} value={third[2].uniqueString}>
                {words.filter(w => w.category === 'owner').sort((a, b) => a.text.localeCompare(b.text)).map(w => <option
                    key={w._id}
                    value={w.uniqueString}>{w.text} ({w.text.length - third[2].text.length})</option>)}
            </select>
            <select onChange={(e) => editWord(e, 'third', 3)} value={third[3].uniqueString}>
                {words.filter(w => w.category === 'what').sort((a, b) => a.text.localeCompare(b.text)).map(w => <option
                    key={w._id}
                    value={w.uniqueString}>{w.text} ({w.text.length - third[3].text.length})</option>)}
            </select>
        </div>}
        {fourth && <div id={'fourthPart'}>
            <select onChange={(e) => editWord(e, 'fourth', 0)} value={fourth[0]?.uniqueString}>
                {words.filter(w => w.category === 'titleBreed').sort((a, b) => a.text.localeCompare(b.text)).map(w =>
                    <option key={w._id}
                            value={w.uniqueString}>{w.text} ({w.text.length - (fourth[0]?.text?.length || 0)})</option>)}
            </select>

        </div>}

        <hr/>

        {combos.filter(a => a.title.length).map(c => <div key={c._id}>
            <h4 style={{background: c.used ? "darkred" : "none"}}>{c.text.split(' ').map(a => capitalizeFirstLetter(a)).join(' ')}
                <input type={'checkbox'} checked={c.used} data-text={c.text} onChange={setUsedFunc}
                       style={{width: 20, height: 20}}/>
            </h4>
            <textarea value={tempComments['c' + c._id] || c.comment} data-id={c._id} data-text={c.text}
                      style={{width: "45%", float:"right", marginTop:-10}}
                      onInput={storeTempComment} onChange={updateCommentFunc}></textarea>
            <button
                onClick={() => copyStringToClipboard(c.text.split(' ').map(a => capitalizeFirstLetter(a)).join(' '))}>Copy
                Title
            </button>
            {!!c.tags.length &&
                <button data-id={c._id} onClick={() => copyStringToClipboard(c.tags.join(','))}>Copy Tags</button>}

            <ul style={{color: 'green'}} key={'st' + c._id}>{c.tags?.map((set, index) => <li
                style={{display: "inline", margin: 10}}
                key={`tt${index}${c._id}`}>"{set}"</li>)}</ul>
            <ul style={{color: 'orange'}} key={'tt' + c._id}>{tempTags['tt' + c._id]?.texts?.map((set, index) => <li
                key={`tt${index}${c._id}`}>"{set}" ({set.length}) <button onClick={removeTagFunc} data-cid={c._id}
                                                                          data-tindex={index}>del</button></li>)}</ul>
            {c?.tags.length === 0 && <button onClick={() => generateTags(c)}> generate tags</button>}
            {!!tempTags['tt' + c._id] && <button data-id={c._id} onClick={saveTagsFunc}>Save New Tags</button>}
            <hr/>
        </div>)}

    </div>;
};
export default IndexPage;
