import "./TagsOnlyPage.css";
import {useUserContext} from "../../../context/userContext/context";
import {
    capitalizeFirstLetter,
    copyStringToClipboard,
    findUniqueDuplicates,
    shuffle,
    timeSince
} from '../../../utils/utils'
import {Link, NavLink} from "react-router-dom";
import {useEffect, useState} from "react";

const TagsOnlyPage = () => {
    const {user, words, combos, getPanelData, storeCombo} = useUserContext()
    const [title, setTitle] = useState('');
    const [tempTags, setTempTags] = useState({})
    const [tempComments, setTempComments] = useState({})

    useEffect(() => {
        getPanelData()
    }, []);

    const storeTitle = () => {
        let combo = {text: title, usedWords: title.replaceAll(',', '').split(' ')}
        storeCombo(combo)
    }

    const generateTags = (c, tagsData = {texts: [], data: []}) => {
        let tempArr = [...c.usedWords]
        let tagCats = ['tagCombos', 'tagBreeds', 'tagOccasions', 'tagFamily', 'tagShortName', 'tagPetDesc',
            'tagObjectDesc', 'tagObject', 'tagVerb', 'tagNumeric', 'tagSize', 'tagAnimals', 'tagMaterials', 'tagOthers']
        let {texts, data} = tagsData
        while (texts.length < 13) {
            let newTag = '', building = true
            while (building) {
                let maxTagLen = (20 - newTag.length), minTagLen = Math.max((20 - newTag.length) - 13, 0);
                let tagWords = shuffle(words.filter(w => w.text.length <= maxTagLen && w.text.length > minTagLen && tagCats.includes(w.category) && !w.text.split(' ').find(p => tempArr.includes(p)))).sort((a, b) => a.useCases.length - b.useCases.length)
                let randomTag = tagWords[0]
                if (randomTag) {
                    newTag += randomTag.text
                    data.push(randomTag)
                    tempArr.push(...(randomTag.text.split(' ')))
                    if (newTag.length < 20) newTag += ' '
                    else {

                        texts.push(newTag.trim())
                    }
                } else {
                    building = false
                    if (!texts.includes(newTag.trim())) {
                        texts.push(newTag.trim())
                    }
                }
            }
        }
        let newTemp = {...tempTags}
        newTemp['tt' + c._id] = {texts, data}
        setTempTags(newTemp)
    }

    const saveTagsFunc = (e) => {
        let _id = e.target.getAttribute('data-id')
        storeCombo({
            text: combos.find(c => c._id === _id).text,
            tags: tempTags["tt" + _id].texts,
            tagData: tempTags["tt" + _id].data
        })
        let newTemp = {...tempTags}
        delete newTemp["tt" + _id]
        setTempTags(newTemp)
    }

    const removeTagFunc = (e) => {
        let cid = e.target.getAttribute('data-cid')
        let tindex = e.target.getAttribute('data-tindex')
        let combo = combos.find(c => c._id === cid)
        let tagsData = tempTags[`tt${cid}`]
        tagsData.texts.splice(tindex, 1)
        tagsData.data.splice(tindex, 1)

        generateTags(combo, tagsData)
    }
    const setUsedFunc = (e)=>{
        storeCombo({text:e.target.getAttribute('data-text'),used:e.target.checked})
    }

    const storeTempComment = (e) => {
        let newTemp = {...tempComments}
        let id = e.target.getAttribute('data-id')
        newTemp['c' + id] = e.target.value
        setTempComments(newTemp)
    }
    const updateCommentFunc = (e) => {
        storeCombo({text: e.target.getAttribute('data-text'), comment: e.target.value})
    }

    return <div>
        <input value={title} onChange={(e) => setTitle(e.target.value)} placeholder={'input current title'}/>
        <button onClick={storeTitle}>Store</button>


            <hr/>

            {combos.filter(a => a.title.length === 0).map(c => <div key={c._id}>
                <h4 style={{background:c.used?"darkred":"none"}}>{c.text.split(' ').map(a => capitalizeFirstLetter(a)).join(' ')}
                    <input type={'checkbox'} checked={c.used} data-text={c.text} onClick={setUsedFunc} style={{width:20, height:20}}/>
                </h4>
                <textarea value={tempComments['c' + c._id] || c.comment} data-id={c._id} data-text={c.text}
                          style={{width: "45%", float:"right", marginTop:-10}}
                          onInput={storeTempComment} onChange={updateCommentFunc}></textarea>
                <button
                    onClick={() => copyStringToClipboard(c.text.split(' ').map(a => capitalizeFirstLetter(a)).join(' '))}>Copy
                    Title
                </button>
                {!!c.tags.length &&
                    <button data-id={c._id} onClick={() => copyStringToClipboard(c.tags.join(','))}>Copy Tags</button>}

                <ul style={{color: 'green'}} key={'st' + c._id}>{c.tags?.map((set, index) => <li
                    style={{display: "inline", margin: 10}}
                    key={`tt${index}${c._id}`}>"{set}"</li>)}</ul>
                <ul style={{color: 'orange'}} key={'tt' + c._id}>{tempTags['tt' + c._id]?.texts?.map((set, index) => <li
                    key={`tt${index}${c._id}`}>"{set}" ({set.length}) <button onClick={removeTagFunc} data-cid={c._id}
                                                                              data-tindex={index}>del</button>
                </li>)}</ul>
                {c?.tags.length === 0 && <button onClick={() => generateTags(c)}> generate tags</button>}
                {!!tempTags['tt' + c._id] && <button data-id={c._id} onClick={saveTagsFunc}>Save New Tags</button>}
                <hr/>
            </div>)}

        </div>;
        };
        export default TagsOnlyPage;
