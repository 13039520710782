export function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " mon";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hr";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " min";
    }
    return Math.floor(seconds) + " seconds";
}

export function randomEl(arr) {
    return arr[randomNum(0, arr.length - 1)]
}

export function randomNum(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function wait(sec) {
    return new Promise((r, j) => setTimeout(r, sec * 1000))
}

export function randomString(length = 15) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function removeDuplicates(myArr, prop = '') {
    if (!prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.indexOf(obj) === pos;
        });
    } else return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
}

export function decodeBase64(encodedText) {
    let binaryString = atob(encodedText.replace(/-/g, '+').replace(/_/g, '/'));
    let bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    let decoder = new TextDecoder("utf-8");
    return decoder.decode(bytes);
}

export function decodeFileToBlob(encodedData = '', mimeType = 'image/png') {
    const decodedData = atob(encodedData.replace(/-/g, '+').replace(/_/g, '/'));
    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
        uint8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uint8Array], {type: mimeType});
}

export function encodeToBase64(text) {
    // Encode as UTF-8
    const utf8Encoder = new TextEncoder();
    const encoded = utf8Encoder.encode(text);

    // Convert to Base64
    return btoa(String.fromCharCode.apply(null, new Uint8Array(encoded)));
}

export function copyStringToClipboard(string) {
    var el = document.createElement('textarea');
    el.value = string;
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

export function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function findUniqueDuplicates(arr) {
    // Function to count occurrences of each element
    const countOccurrences = arr =>
        arr.reduce((acc, val) => {
            acc[val] = (acc[val] || 0) + 1;
            return acc;
        }, {});

    const occurrences = countOccurrences(arr);

    // Filter elements that occur more than once
    const duplicates = arr.filter(item => occurrences[item] > 1);

    // Convert to Set and back to array to remove extra duplicates
    return [...new Set(duplicates)];
}
export function shuffle(array) {
    let tmp, current, top = array.length;
    if (top) while (--top) {
        current = Math.floor(Math.random() * (top + 1));
        tmp = array[current];
        array[current] = array[top];
        array[top] = tmp;
    }
    return array;
}
